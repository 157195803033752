<template>
  <svg class="svg-inline--fa" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 49">
    <path d="M10.5 23L40.5 23C40.8978 23 41.2794 23.158 41.5607 23.4393C41.842 23.7206 42 24.1022 42 24.5C42 24.8978 41.842 25.2794 41.5607 25.5607C41.2794 25.842 40.8978 26 40.5 26L10.5 26C10.1022 26 9.72064 25.842 9.43934 25.5607C9.15804 25.2794 9 24.8978 9 24.5C9 24.1022 9.15804 23.7206 9.43934 23.4393C9.72064 23.158 10.1022 23 10.5 23Z" fill="currentColor"/>
    <path d="M11.1208 24.4999L23.5618 36.9379C23.8434 37.2196 24.0016 37.6016 24.0016 37.9999C24.0016 38.3983 23.8434 38.7803 23.5618 39.0619C23.2801 39.3436 22.8981 39.5018 22.4997 39.5018C22.1014 39.5018 21.7194 39.3436 21.4377 39.0619L7.93775 25.5619C7.79806 25.4226 7.68723 25.2571 7.61161 25.0748C7.53599 24.8926 7.49707 24.6972 7.49707 24.4999C7.49707 24.3026 7.53599 24.1073 7.61161 23.925C7.68723 23.7428 7.79806 23.5773 7.93775 23.4379L21.4377 9.93794C21.7194 9.65628 22.1014 9.49805 22.4997 9.49805C22.8981 9.49805 23.2801 9.65628 23.5618 9.93794C23.8434 10.2196 24.0016 10.6016 24.0016 10.9999C24.0016 11.3983 23.8434 11.7803 23.5618 12.0619L11.1208 24.4999Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VIconBack'
}
</script>
