export const WEBOS_LUNA_API_DEVICE_INFOMATION = 'luna://com.webos.service.tv.systemproperty'
export const WEBOS_LUNA_API_DEVICE_UNIQUE_ID = 'luna://com.webos.service.sm'

export const PLAYER_USER_ACTIVITY = 5000
export const PLAYER_TIME_UPDATE = 125
export const PLAYER_STEP_SECONDS = 10
export const PLAYER_SKIP_DELAY_SECONDS = 20
export const PLAYER_SKIP_INTRO = 'intro'
export const PLAYER_SKIP_RECAP = 'recap'
export const PLAYER_SKIP_CREDIT = 'end'
export const PLAYER_SUBTITLE_BACKGROUND_COLOR = 'standard'
export const PLAYER_SUBTITLE_TEXT_SIZE = 'standard'

export const PACKAGE_TIER_STARTER = 'STARTER'
export const PACKAGE_TIER_MINI = 'MINI'
export const PACKAGE_TIER_JUMBO = 'JUMBO'
export const PACKAGE_GENERAL_PRICE = 99
export const PACKAGE_AIS_MONTHLY_PRICE = 59
export const PACKAGE_AIS_MONTHLY_USSD = '*678*4#'
export const PACKAGE_AIS_YEARLY_PRICE = 499
export const PACKAGE_AIS_YEARLY_USSD = '*678*6#'
export const PACKAGE_TYPE_FASTTRACK = 'fasttrack'
export const PACKAGE_TYPE_UNLIMIT_FASTTRACK = 'unlimit_fasttrack'
export const PACKAGE_SKU_FASTTRACK_1M = 'unlimit_fasttrack_1m'

export const PACKAGE_UPDATED_TIMEOUT = 1000 * 3 // 3 seconds.

export const PUSH_MEMBER_CHANNEL = 'private-member.'
export const PUSH_FASTTRACK_PURCHASE_EVENT = 'fasttrack.purchased'
export const PUSH_EXTRA_PACKAGE_PURCHASE_EVENT = 'extrapackage.purchased'
export const PUSH_PACKAGE_UPDATED_EVENT = 'package.updated'

export const REFRESH_STREAM_TIMEOUT = 1000 * 60 * 60 * 5 // 5 hours.
