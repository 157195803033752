import {
  fetchFastTrackPackages
} from '@/api'

import _filter from 'lodash/filter'

import {
  PACKAGE_TYPE_FASTTRACK,
  PACKAGE_TYPE_UNLIMIT_FASTTRACK
} from '@/utils/constants'

const namespaced = true

const state = {
  fastTrackPackages: [],
  package: null,
  redirectBack: null
}

const mutations = {
  SET_PACKAGE (state, pkg) {
    state.package = pkg
  },

  SET_FASTTRACK_PACKAGES (state, packages) {
    state.fastTrackPackages = packages
  },

  SET_REDIRECT_BACK (state, page) {
    state.redirectBack = page
  },

  RESET_STATE (state) {
    state.fastTrackPackages = []
    state.package = null
    state.redirectBack = null
  }
}

const actions = {
  FETCH_FASTTRACK_PACKAGES ({ commit }, { pId, subId }) {
    if (!pId || !subId) {
      return Promise.resolve()
    }

    return fetchFastTrackPackages(pId, subId)
      .then(({ data }) => _filter(data, (o) => [PACKAGE_TYPE_FASTTRACK, PACKAGE_TYPE_UNLIMIT_FASTTRACK].indexOf(o.type) !== -1))
      .then((data) => commit('SET_FASTTRACK_PACKAGES', data))
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions
}
