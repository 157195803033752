<template>
  <span class="v-icon">
    <slot>
      <font-awesome-icon
        v-bind="{
          icon: icon,
          ...options
        }"
      />
    </slot>
  </span>
</template>

<script>
export default {
  name: 'VIcon',

  props: {
    icon: [String, Array],
    options: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
@import './VIcon.scss';
</style>
