import VIcon from './VIcon.vue'

import VIconBack from './icons/VIconBack.vue'
import VIconNext from './icons/VIconNext.vue'
import VIconUnlock from './icons/VIconUnlock.vue'

export default VIcon

export {
  VIcon,
  VIconBack,
  VIconNext,
  VIconUnlock
}
