<template>
  <router-link
    v-slot="{ navigate }"
    :to="{
      name: 'product',
      params: { id: this.clip.product.id }
    }"
    custom
  >
    <v-card
      v-sn-focusable
      class="v-carousel--slide--link clipitem"
      data-sn-autofocus="disable"
      @sn:active.native="handleActive"
      @sn:deactive.native="handleDeactive"
      @keypress.enter.native="navigate"
      @click.native="navigate"
      role="link"
    >
      <div class="clipitem--preview">
        <div class="clipitem--preview--responsive"></div>

        <div class="clipitem--preview--video">
          <v-player
            v-if="activePlayer"
            :shakaOptions="{
              streaming: { preferNativeHls: true }
            }"
            :ui="false"
            :playlists="[{
              sources: [{
                streamURL: clip.stream_url,
                streamMimeType: 'application/x-mpegurl'
              }]
            }]"
            @player:play="handleVideoPlay"
            @player:ended="handleVideoEnded"
            @player:error="activePlayer = false"
            autoplay
            ref="player"
          />
        </div>

        <v-fade-transition
          @fadein="activePlayer = false"
        >
          <div
            v-show="!showVideo"
            class="clipitem--preview--image"
          >
            <v-image :aspectRatio=".5625">
              <img
                class="v-image--image img-lazy"
                :data-lazy="clip.thumbnail"
                :alt="clip.title"
              >

              <div class="v-image--icon">
                <v-icon icon="circle-play" />
              </div>
            </v-image>
          </div>
        </v-fade-transition>

        <div class="clipitem--preview--mask"></div>
      </div>

      <div class="v-card--title px-0 py-2">
        {{ clip.title }}
      </div>
    </v-card>
  </router-link>
</template>

<script>
import VCard from '@/components/VCard'
import VImage from '@/components/VImage'
import VIcon from '@/components/VIcon'
import VPlayer from '@/components/VPlayer'
import VFadeTransition from '@/transitions/VFadeTransition.vue'

export default {
  name: 'ClipItem',

  props: {
    index: Number,
    clip: Object
  },

  data () {
    return {
      showVideo: false,
      activePlayer: false
    }
  },

  components: {
    VCard,
    VImage,
    VIcon,
    VPlayer,
    VFadeTransition
  },

  methods: {
    _clearShowTimer () {
      this.showVideoTimer && clearTimeout(this.showVideoTimer)
      this.showVideoTimer = null
    },

    handleVideoPlay () {
      if (!this.hasVideoPlayed) {
        this.showVideo = true
      }

      this.hasVideoPlayed = true
    },

    handleVideoEnded () {
      const player_ = this.$refs.player
      const { index, playlists } = player_.playback
      const isLastTrack = index === playlists.length - 1

      if (isLastTrack) {
        this.showVideo = false
      }
    },

    handleActive () {
      const hasVideoPreview = !!this.clip.stream_url

      if (!hasVideoPreview) {
        return
      }

      this._clearShowTimer()
      this.hasVideoPlayed = false

      this.showVideoTimer = setTimeout(() => this.activePlayer = true, 3000) // 3 seconds
    },

    handleDeactive () {
      const player_ = this.$refs.player

      this._clearShowTimer()

      if (this.activePlayer) {
        player_.pause()
      }

      this.showVideo = false
    },

    goToProductPage () {
      this.$router.push({
        name: 'product',
        params: { id: this.clip.product.id }
      })
    }
  }
}
</script>
